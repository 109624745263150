@tailwind base;
@tailwind components;
@tailwind utilities;

p {
  margin-bottom: 0 !important;
}

.ant-select-selection-item {
  color: rgb(75 85 99);
  font-weight: 600 !important;
}

.css-jh47zj-MuiButtonBase-root-MuiButton-root {
  background-color: rgb(20 184 166) !important;
}

.ant-spin-dot-item {
  background-color: rgb(20, 184, 166) !important;
}

.braintreeLoading .braintree-loaded {
  margin-top: 2rem !important;
}

.spinner .ant-spin-dot-item {
  background-color: white !important;
}