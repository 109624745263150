.ant-card-body {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  position: relative;
}

.ant-rate {
  font-size: 0.85rem !important;
}

.payment-system-dropdown {
  background-color: #14B8A6 !important;
  font-size: 0.75rem !important;
  width: 5rem !important;
  color: white !important;
  outline: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px !important;
}